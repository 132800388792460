import React, { useEffect } from 'react';
import MovieSchedule from './MovieSchedule';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css'; // Import your CSS for styling
import './assets/fonts/bogueslab-semibold.otf';

import logo from './assets/eventspire.png'; // Adjust the path based on your file structure

const App = () => {
  useEffect(() => {
    document.title = "eventspire";

    // Dynamically add the Plausible Analytics script
    const script = document.createElement("script");
    script.setAttribute("defer", "");
    script.setAttribute("data-domain", "eventspire.nl");
    script.setAttribute("data-api", "/api/event"); // Add the data-api attribute
    script.setAttribute("src", "/js/script.js"); // Use the proxied endpoint
    document.head.appendChild(script);


    // Cleanup function to remove the script if needed
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="app-header">
          <Link to="/">
            <img src={logo} alt="Eventspire Logo" className="app-logo" />
          </Link>
        </header>
        <Routes>
          <Route path="/" element={<MovieSchedule />} />
          {/* Define other routes here if needed */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
