import React, { useEffect, useState } from 'react';

// Main component to display the movie schedule
const MovieSchedule = () => {
  const [movies, setMovies] = useState([]);
  const [error, setError] = useState(null);
  const [tooltip, setTooltip] = useState({ visible: false, text: '', top: 0, left: 0 });

  // Initialize selectedDate with today's date in 'YYYY-MM-DD' format
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  });

  const dateOptions = [
    { label: 'Today', value: new Date().toISOString().split('T')[0] },
    { label: 'Tomorrow', value: new Date(Date.now() + 86400000).toISOString().split('T')[0] },
    { label: 'In 2 days', value: new Date(Date.now() + 2 * 86400000).toISOString().split('T')[0] },
  ];

  const fetchMovies = async (date = '') => {
    try {
      const queryString = date ? `?date=${date}` : '';
      const response = await fetch(`https://eventspire.nl/api/movies_with_cinemas/${queryString}`);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
      setMovies(data);
    } catch (error) {
      setError(error);
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    fetchMovies(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (e) => {
    const newSelectedDate = e.target.value;
    setSelectedDate(newSelectedDate);
    // Removed fetchMovies call here to prevent duplicate fetches
  };

  const handleMouseEnter = (e, cinemaName, showtimes) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const showtimesText = showtimes.join('\n');
    const offset = 10;

    setTooltip({
      visible: true,
      text: `${cinemaName}:\n${showtimesText}`,
      top: rect.top - offset,
      left: rect.left + (rect.width / 2),
    });
  };

  const handleMouseLeave = () => {
    setTooltip({ visible: false, text: '', top: 0, left: 0 });
 };

  // Render error message if there's an error
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="movie-schedule-container">
      <div className="movie-schedule">
        <div className="selector-row">
          <div className="city-selector">
            <label htmlFor="city-select">Amsterdam</label>
          </div>
          <div className="date-selector">
            <label htmlFor="date-select">
              <a href="https://www.cineville.nl" target="_blank" rel="noopener noreferrer">
                Cineville </a>
               movies of </label>
            <select id="date-select" value={selectedDate} onChange={handleDateChange} className="clickable-select">
              {dateOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        {movies.length > 0 ? (
          movies.map((movie, index) => (
            <MovieCard
              key={movie.id}
              movie={movie}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              className={index === 0 ? 'first-movie-card' : ''}
            />
          ))
        ) : (
          <p>No movies available</p>
        )}

        {tooltip.visible && (
          <div className="tooltip" style={{ top: tooltip.top, left: tooltip.left }}>
            {tooltip.text}
          </div>
        )}
      </div>
    </div>
  );
};

const MovieCard = ({ movie, handleMouseEnter, handleMouseLeave, className }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const descriptionLength = 350;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
   };

  const truncateText = (text, length) => {
    if (!text) return '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  return (
    <div className={`movie-card ${className}`}>
      <div className="movie-image">
        {movie.image_url && (
          movie.trailer_url ? (
            <a
              href={movie.trailer_url}
              target="_blank"
              rel="noopener noreferrer"
              className="youtube-link"
            >
              <img
                src={movie.image_url}
                alt={`${movie.original_title || 'Unknown'} poster`}
                onError={(e) => { e.target.style.display = 'none'; }}
              />
            </a>
          ) : (
            <img
              src={movie.image_url}
              alt={`${movie.original_title || 'Unknown'} poster`}
              onError={(e) => { e.target.style.display = 'none'; }}
            />
          )
        )}
      </div>
      <div className="movie-info">
        <h2 className="movie-title">
          {movie.tmdb_id ? (
              <a
                  href={`https://www.themoviedb.org/movie/${encodeURIComponent(movie.tmdb_id)}-${encodeURIComponent(movie.eng_title)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="movie-link"
              >
                {movie.original_title || 'Unknown Title'}
              </a>
          ) : (
              <span className="movie-title">
              {movie.original_title || 'Unknown Title'}
            </span>
          )}
            <span className="movie-year">
              {movie.year_of_release ? `(${movie.year_of_release})` : ''}
            </span></h2>
        <div className="movie-details">
          {formatDuration(movie.duration) !== "0hr 0min" && formatDuration(movie.duration) !== "0min" && (
              <span className="movie-duration">{formatDuration(movie.duration)}</span>
          )}
          {movie.rotten_tomatoes_rating !== null && (
              <div className="movie-rating-container">
                <svg viewBox="0 0 106.25 140" width="12.88" height="16.97" className="rotten-svg">
                  <path fill="#fa3106"
                        d="M2.727 39.537c-.471-21.981 100.88-25.089 100.88-.42L92.91 117.56c-7.605 26.86-72.064 27.007-79.07.21z"/>
                  <g fill="#fff">
                    <path
                        d="M8.809 51.911l9.018 66.639c3.472 4.515 8.498 7.384 9.648 8.022l-6.921-68.576c-3.498-1.41-9.881-4.579-11.745-6.083zM28.629 59.776l5.453 68.898c4.926 2.652 11.04 3.391 15.73 3.566l-1.258-70.366c3.414-.024-13.82-.642-19.925-2.098zM97.632 52.121l-9.019 66.643c-3.472 4.515-8.498 7.384-9.647 8.022l6.92-68.583c3.5-1.41 9.882-4.579 11.746-6.082zM77.812 59.986l-5.453 68.898c-4.926 2.652-11.04 3.391-15.73 3.566l1.258-70.366c3.414-.024 13.82-.642 19.925-2.098z"/>
                  </g>
                  <g fill="#ffd600">
                    <circle cx="13.213" cy="31.252" r="6.816"/>
                    <circle cx="22.022" cy="27.687" r="6.607"/>
                    <circle cx="30.359" cy="19.769" r="5.925"/>
                    <circle cx="34.973" cy="15.155" r="6.03"/>
                    <circle cx="45.093" cy="17.095" r="4.929"/>
                    <circle cx="51.123" cy="9.597" r="6.24"/>
                    <circle cx="61.19" cy="9.387" r="6.554"/>
                    <circle cx="67.954" cy="13.635" r="4.929"/>
                    <circle cx="76.081" cy="17.672" r="5.925"/>
                    <circle cx="78.913" cy="22.706" r="4.352"/>
                    <circle cx="83.475" cy="26.324" r="5.243"/>
                    <circle cx="88.194" cy="34.398" r="5.768"/>
                    <path
                        d="M87.355 35.447c5.79 2.799 1.352-2.213 10.696 2.097-9.574 15.338-74.774 16.892-90.291.525l-.21-3.985L38.59 16.99l22.863-6.606 15.52 9.962z"/>
                  </g>
                </svg>
                <span className="movie-rating">{movie.rotten_tomatoes_rating}</span>
              </div>
          )}
          {movie.imdb_rating !== null && (
              <div className="imdb-rating-container">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet"
                     viewBox="0 0 575 289.83" className="imdb-svg">
                  <defs>
                    <path
                        d="M575 24.91C573.44 12.15 563.97 1.98 551.91 0C499.05 0 76.18 0 23.32 0C10.11 2.17 0 14.16 0 28.61C0 51.84 0 237.64 0 260.86C0 276.86 12.37 289.83 27.64 289.83C79.63 289.83 495.6 289.83 547.59 289.83C561.65 289.83 573.26 278.82 575 264.57C575 216.64 575 48.87 575 24.91Z"
                        id="d1pwhf9wy2"/>
                    <path d="M69.35 58.24L114.98 58.24L114.98 233.89L69.35 233.89L69.35 58.24Z" id="g5jjnq26yS"/>
                    <path
                        d="M201.2 139.15C197.28 112.38 195.1 97.5 194.67 94.53C192.76 80.2 190.94 67.73 189.2 57.09C185.25 57.09 165.54 57.09 130.04 57.09L130.04 232.74L170.01 232.74L170.15 116.76L186.97 232.74L215.44 232.74L231.39 114.18L231.54 232.74L271.38 232.74L271.38 57.09L211.77 57.09L201.2 139.15Z"
                        id="i3Prh1JpXt"/>
                    <path
                        d="M346.71 93.63C347.21 95.87 347.47 100.95 347.47 108.89C347.47 115.7 347.47 170.18 347.47 176.99C347.47 188.68 346.71 195.84 345.2 198.48C343.68 201.12 339.64 202.43 333.09 202.43C333.09 190.9 333.09 98.66 333.09 87.13C338.06 87.13 341.45 87.66 343.25 88.7C345.05 89.75 346.21 91.39 346.71 93.63ZM367.32 230.95C372.75 229.76 377.31 227.66 381.01 224.67C384.7 221.67 387.29 217.52 388.77 212.21C390.26 206.91 391.14 196.38 391.14 180.63C391.14 174.47 391.14 125.12 391.14 118.95C391.14 102.33 390.49 91.19 389.48 85.53C388.46 79.86 385.93 74.71 381.88 70.09C377.82 65.47 371.9 62.15 364.12 60.13C356.33 58.11 343.63 57.09 321.54 57.09C319.27 57.09 307.93 57.09 287.5 57.09L287.5 232.74L342.78 232.74C355.52 232.34 363.7 231.75 367.32 230.95Z"
                        id="a4ov9rRGQm"/>
                    <path
                        d="M464.76 204.7C463.92 206.93 460.24 208.06 457.46 208.06C454.74 208.06 452.93 206.98 452.01 204.81C451.09 202.65 450.64 197.72 450.64 190C450.64 185.36 450.64 148.22 450.64 143.58C450.64 135.58 451.04 130.59 451.85 128.6C452.65 126.63 454.41 125.63 457.13 125.63C459.91 125.63 463.64 126.76 464.6 129.03C465.55 131.3 466.03 136.15 466.03 143.58C466.03 146.58 466.03 161.58 466.03 188.59C465.74 197.84 465.32 203.21 464.76 204.7ZM406.68 231.21L447.76 231.21C449.47 224.5 450.41 220.77 450.6 220.02C454.32 224.52 458.41 227.9 462.9 230.14C467.37 232.39 474.06 233.51 479.24 233.51C486.45 233.51 492.67 231.62 497.92 227.83C503.16 224.05 506.5 219.57 507.92 214.42C509.34 209.26 510.05 201.42 510.05 190.88C510.05 185.95 510.05 146.53 510.05 141.6C510.05 131 509.81 124.08 509.34 120.83C508.87 117.58 507.47 114.27 505.14 110.88C502.81 107.49 499.42 104.86 494.98 102.98C490.54 101.1 485.3 100.16 479.26 100.16C474.01 100.16 467.29 101.21 462.81 103.28C458.34 105.35 454.28 108.49 450.64 112.7C450.64 108.89 450.64 89.85 450.64 55.56L406.68 55.56L406.68 231.21Z"
                        id="fk968BpsX"/>
                  </defs>
                  <g>
                    <g>
                      <g>
                        <use href="#d1pwhf9wy2" opacity="1" fill="#f6c700" fillOpacity="1"/>
                        <g>
                          <use href="#d1pwhf9wy2" opacity="1" fillOpacity="0" stroke="#000000" strokeWidth="1"
                               strokeOpacity="0"/>
                        </g>
                      </g>
                      <g>
                        <use href="#g5jjnq26yS" opacity="1" fill="#000000" fillOpacity="1"/>
                        <g>
                          <use href="#g5jjnq26yS" opacity="1" fillOpacity="0" stroke="#000000" strokeWidth="1"
                               strokeOpacity="0"/>
                        </g>
                      </g>
                      <g>
                        <use href="#i3Prh1JpXt" opacity="1" fill="#000000" fillOpacity="1"/>
                        <g>
                          <use href="#i3Prh1JpXt" opacity="1" fillOpacity="0" stroke="#000000" strokeWidth="1"
                               strokeOpacity="0"/>
                        </g>
                      </g>
                      <g>
                        <use href="#a4ov9rRGQm" opacity="1" fill="#000000" fillOpacity="1"/>
                        <g>
                          <use href="#a4ov9rRGQm" opacity="1" fillOpacity="0" stroke="#000000" strokeWidth="1"
                               strokeOpacity="0"/>
                        </g>
                      </g>
                      <g>
                        <use href="#fk968BpsX" opacity="1" fill="#000000" fillOpacity="1"/>
                        <g>
                          <use href="#fk968BpsX" opacity="1" fillOpacity="0" stroke="#000000" strokeWidth="1"
                               strokeOpacity="0"/>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span className="imdb-rating">{movie.imdb_rating}</span>
              </div>
          )}
          <span className="movie-genre">
            {movie.genres && movie.genres.length > 0
                ? movie.genres.join(', ')
                : ''}
          </span>
        </div>
        <p className="movie-description">
          {isExpanded ? movie.description : truncateText(movie.description, descriptionLength)}
          {!isExpanded && movie.description && movie.description.length > descriptionLength && (
              <button onClick={handleToggle} className="more-button">
                more
              </button>
          )}
        </p>
        <div className="showtimes">
          {movie.showtimes && movie.showtimes.length > 0 ? (
              <div className="showtime-icons">
                {[...new Set(movie.showtimes.map((showtime) => showtime.cinema_name))].map(
                    (uniqueCinemaName, index) => {
                      const sanitizedCinemaName = uniqueCinemaName.toLowerCase().replace(/\s+/g, '').replace(/\//g, '');
                      const logoPath = `https://eventspire.nl/media/${sanitizedCinemaName}.png`;

                      // Define the link for the cinema

                      const cinemaUrl = movie.showtimes.find(showtime => showtime.cinema_name === uniqueCinemaName)?.cinema_url;
                      //const encodedMovieName = encodeURIComponent(movie.original_title);
                      //const fullCinemaUrl = cinemaUrl ? `${cinemaUrl}${encodedMovieName}` : '';

                      const cinemaShowtimes = movie.showtimes
                          .filter(showtime => showtime.cinema_name === uniqueCinemaName)
                          .map(showtime => showtime.start_time.slice(0, 5));

                      return (
                          <div
                              key={index}
                              className="cinema-icon-container"
                              onMouseEnter={(e) => handleMouseEnter(e, uniqueCinemaName, cinemaShowtimes)}
                              onMouseLeave={handleMouseLeave}
                          >
                            <a href={cinemaUrl} target="_blank" rel="noopener noreferrer">
                              <img
                                  src={logoPath}
                                  alt={`${uniqueCinemaName} logo`}
                                  className="cinema-logo"
                                  onError={(e) => {
                                    e.target.style.display = 'none';
                                  }}
                              />
                            </a>
                          </div>
                      );
                    }
                )}
              </div>
          ) : (
              <p>No showtimes</p>
          )}
        </div>

      </div>
    </div>
  );
};

// Helper function to format movie duration from minutes to 'Xhr Ymin'
const formatDuration = (minutes) => {
  if (!minutes && minutes !== 0) return '';
  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;
  if (hrs === 0) {
    return `${mins}min`;
  }
  return `${hrs}hr ${mins}min`;
};

export default MovieSchedule;
